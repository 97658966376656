import type { UserData } from '../types';

export function getNextDayOfTheWeek(dayName: string, excludeToday = true, refDate = new Date()) {
	const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(
		dayName.slice(0, 3).toLowerCase()
	);
	if (dayOfWeek < 0) return;
	refDate.setHours(0, 0, 0, 0);
	refDate.setDate(
		refDate.getDate() + +!!excludeToday + ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
	);
	return refDate;
}

export function prettyPrintDate(date: Date | undefined): string {
	if (!date) {
		return '';
	}
	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	return `${daysOfWeek[date.getDay()]}, ${date.getMonth() + 1}/${date.getDate()}`;
}

export function getYYYYMMDDFromDateTime(dateTime: Date): number {
	const day = dateTime.getDate();
	const month = dateTime.getMonth();
	const paddedMonth = `${month + 1}`.padStart(2, '0');
	const year = dateTime.getFullYear();
	return Number.parseInt(`${year}${paddedMonth}${day}`);
}

export function get12MonthRange() {
	const sixMonthsAgo = new Date();
	sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);
	const sixMonthsFromNow = new Date();
	sixMonthsFromNow.setDate(sixMonthsFromNow.getDate() + 180);
	return { sixMonthsAgo, sixMonthsFromNow };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugLog(...args: any[]) {
	if (import.meta.env.DEV) {
		console.log(...args);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugWarn(...args: any[]) {
	if (import.meta.env.DEV) {
		console.warn(...args);
	}
}

export function isDemoUser(user: UserData | null): boolean {
	const isDemoUser = !!user?.isDemo;
	return isDemoUser;
}
export const DEFAULT_TOUR_OPTIONS = {
	modalOverlayOpeningPadding: 5,
	modalOverlayOpeningRadius: 5
};

export function waitForElement(selector: string): Promise<Element | null> {
	return new Promise((resolve) => {
		if (document.querySelector(selector)) {
			return resolve(document.querySelector(selector));
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const observer = new MutationObserver((mutations) => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector));
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true
		});
	});
}
