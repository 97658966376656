import * as Sentry from '@sentry/browser';
import {
	GoogleAuthProvider,
	TwitterAuthProvider,
	type UserInfo,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	createUserWithEmailAndPassword,
	type UserCredential,
	type User,
	updateProfile,
	sendPasswordResetEmail,
	type AuthError
} from 'firebase/auth';
import { get, writable } from 'svelte/store';

import { auth } from '../firebase';
import type { FirebaseError } from 'firebase/app';
import { addUser, getUser } from '$db/userDao';
import { debugLog } from '$lib/utils';

const authStore = writable<{
	isLoggedIn: boolean;
	user: UserInfo | null;
	waitingForFirebase: boolean;
	errorOccurred?: boolean;
	errorMessage?: string;
}>(
	{
		isLoggedIn: false,
		user: null,
		waitingForFirebase: true,
		errorOccurred: false
	},
	() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			debugLog('onAuthStateChanged detected user change', user?.uid);
			updateStore(user, false);
		});
		return unsubscribe;
	}
);

const updateStore = (user: UserInfo | null, isError?: boolean, errorMessage?: string) => {
	authStore.set({
		isLoggedIn: !!user,
		user: user,
		waitingForFirebase: false,
		errorOccurred: isError,
		errorMessage: errorMessage
	});
};

const reset = () => {
	authStore.set({
		isLoggedIn: false,
		user: null,
		waitingForFirebase: false,
		errorOccurred: false,
		errorMessage: undefined
	});
};

const signUpWithEmail = async (
	name: string,
	email: string,
	password: string,
	placeId?: string | null,
	isDemo = false
) => {
	try {
		const { user } = await createUserWithEmailAndPassword(auth, email, password);
		await updateProfile(user, { displayName: name });
		await addUser(user, isDemo, placeId);
		return user;
	} catch (error) {
		console.warn('Sign up error', error);
		const fbError = error as FirebaseError;
		if (fbError?.code !== 'auth/email-already-in-use' && fbError?.code !== 'auth/invalid-email') {
			Sentry.captureException(error, {
				extra: {
					errorMessage: 'Error in signUpWithEmail',
					email
				}
			});
		}
		updateStore(null, true, (error as AuthError).message);
		return null;
	}
};

const loginWithEmail = async (email: string, password: string) => {
	return signInWithEmailAndPassword(auth, email, password)
		.then((result) => {
			debugLog('Login successful', result);
			updateStore(result.user, false, undefined);
			return true;
		})
		.catch((error) => {
			console.warn('Login with email error', error);
			updateStore(null, true, error.message);
			return false;
		});
};

const sendPasswordReset = async (email: string) => {
	sendPasswordResetEmail(auth, email)
		.then(() => {
			console.log('Password reset email sent to ' + email);
		})
		.catch((error) => {
			Sentry.captureException(error, {
				extra: {
					errorMessage: 'Error in sendPasswordReset',
					email
				}
			});
			updateStore(null, true, error.message);
		});
};

const loginWithGoogle = async (placeId?: string | null): Promise<boolean> => {
	debugLog('loginWithGoogle', placeId);
	const provider = new GoogleAuthProvider();
	const result = await loginWithProvider(provider, placeId);
	if ((result as UserCredential).user) {
		// const credential = GoogleAuthProvider.credentialFromResult(result as UserCredential);
		// const token = credential?.accessToken;
		return true;
	} else if ((result as FirebaseError).code) {
		// The AuthCredential type that was used.
		// const credential = GoogleAuthProvider.credentialFromError(result as FirebaseError);
		return false;
	} else {
		return false;
	}
};

const loginWithTwitter = async (placeId?: string | null) => {
	debugLog('loginWithTwitter', placeId);
	const provider = new TwitterAuthProvider();
	const result = await loginWithProvider(provider, placeId);
	if ((result as UserCredential).user) {
		// const credential = TwitterAuthProvider.credentialFromResult(result as UserCredential);
		// const token = credential?.accessToken;
		return true;
	} else if ((result as FirebaseError).code) {
		// The AuthCredential type that was used.
		// const credential = GoogleAuthProvider.credentialFromError(result as FirebaseError);
		return false;
	}
};

const loginWithProvider = async (
	provider: GoogleAuthProvider | TwitterAuthProvider,
	placeId?: string | null
): Promise<UserCredential | FirebaseError> => {
	try {
		const result = await signInWithPopup(auth, provider);
		debugLog('Sign in result', result);
		// The signed-in user info.
		const user = result.user;
		const dbUser = await getUser(user.uid);
		if (!dbUser) {
			await addUser(user, false, placeId);
		}
		updateStore(user, false, undefined);
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: unknown) {
		// Handle Errors here.
		const fbError = error as FirebaseError;
		const errorCode = fbError.code;
		const errorMessage: string = fbError.message;
		// The email of the user's account used.
		// const email = error.email;
		if (
			errorCode !== 'auth/cancelled-popup-request' &&
			errorCode !== 'auth/popup-closed-by-user' &&
			errorCode !== 'auth/invalid-credential'
		) {
			console.error('Login sign in error', errorCode, errorMessage);
			Sentry.captureException(error, {
				extra: {
					errorMessage: 'Error in loginWithProvider' + errorMessage,
					errorCode
				}
			});
		}
		return fbError;
	}
};

const logout = () => {
	signOut(auth)
		.then(() => {
			console.log('Logout successful');
			// updateStore will be called by onAuthStateChanged
		})
		.catch((error) => {
			alert('Logout failed');
			console.error('Logout error', error);
			Sentry.captureException(error, {
				extra: {
					errorMessage: 'Error in logout'
				}
			});
			updateStore(null, true, error.message);
		});
};

const waitForFirebase = async (): Promise<User | null> => {
	const waitPromise: Promise<User | null> = new Promise((resolve) => {
		debugLog('Waiting for firebase auth');
		const user = auth.currentUser;
		if (user) {
			resolve(user);
		}
		onAuthStateChanged(auth, (user) => {
			debugLog('Firebase auth ready', user);
			resolve(user);
		});
	});
	return waitPromise;
};

const isDemoUser = (): boolean => {
	const { user } = get(authStore);
	return user?.email === 'demo@simpleweeklymealplanner.com';
};

export default {
	subscribe: authStore.subscribe,
	waitForFirebase,
	loginWithEmail,
	loginWithGoogle,
	loginWithTwitter,
	signUpWithEmail,
	logout,
	reset,
	sendPasswordReset,
	isDemoUser
};
