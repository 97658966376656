import { initializeApp } from 'firebase/app';
import {
	getAuth,
	setPersistence,
	browserLocalPersistence,
	connectAuthEmulator
} from 'firebase/auth';
import {
	getFirestore,
	connectFirestoreEmulator,
	QueryConstraint,
	collection,
	query,
	getDocs
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import type { Idable } from './types';

// Read Firebase properties from .env file.  See https://vitejs.dev/guide/env-and-mode.html#env-files
const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

console.log('🔥 Initializing Firebase 🔥');
if (
	!firebaseConfig.apiKey ||
	!firebaseConfig.authDomain ||
	!firebaseConfig.projectId ||
	!firebaseConfig.messagingSenderId ||
	!firebaseConfig.appId ||
	!firebaseConfig.measurementId ||
	!firebaseConfig.storageBucket
) {
	console.error('💀💀💀 Missing Firebase config - do you have a .env file? 💀💀');
	throw new Error('Missing Firebase config - do you have a .env file');
}

export const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

if (import.meta.env.DEV || import.meta.env.VITE_USEEMULATOR === 'true') {
	console.log('🔥🔥 Connecting to Firebase Emulator 🔥🔥');
	connectAuthEmulator(auth, 'http://localhost:9099');
	connectFirestoreEmulator(db, 'localhost', 8080);
	connectFunctionsEmulator(functions, 'localhost', 5001);
}

setPersistence(auth, browserLocalPersistence);

export async function getAll<T extends Idable>(
	collectionPath: string,
	where: QueryConstraint,
	orderBy: QueryConstraint
) {
	const ref = collection(db, collectionPath);
	const q = query(ref, where, orderBy);
	const snapshot = await getDocs(q);
	const items = snapshot.docs.map((doc) => {
		const data = doc.data();
		return {
			id: doc.id,
			...data
		};
	});
	return items as T[];
}
