import { debugLog } from '$lib/utils';
import type { UserInfo } from 'firebase/auth';
import { doc, setDoc, onSnapshot, type Unsubscribe, getDoc } from 'firebase/firestore';
import type { UserData } from '../types';
import { auth, db } from '../firebase';

import { addMemberToPlace, addPlace } from './placeDao';

export const getUser = async (uid: string): Promise<UserData | null> => {
	const ref = doc(db, `users/${uid}`);
	try {
		const snapshot = await getDoc(ref);
		if (snapshot.exists()) {
			const data = await snapshot.data();
			debugLog(`Found user for uid ${uid}`, data);
			const user: UserData = {
				uid,
				displayName: data.displayName,
				email: data.email,
				placeId: data.placeId,
				isDemo: data.isDemo
			};
			return user;
		} else {
			console.warn(`No user exists for uid ${uid}`);
			return null;
		}
	} catch (error) {
		// getDoc throws error if no user with specified uid is found
		console.warn(`No user exists for uid ${uid}`);
		return null;
	}
};

/**
 * Updates the user info in the Firebase datastore
 * or creates a new user document if it doesn't exist.
 * @param user
 * @param placeId
 * @returns
 */
export const addUser = async (user: UserInfo, isDemo: boolean, placeId?: string | null) => {
	const uid = user.uid;
	const userRef = doc(db, `users/${uid}`);

	debugLog('Adding new /users document for user', uid);
	// but place exists, add user document with existing place id
	if (!placeId) {
		placeId = await addPlace([uid], isDemo);
	}
	await setDoc(userRef, {
		email: auth.currentUser?.email,
		displayName: auth.currentUser?.displayName,
		placeId: placeId,
		isDemo: isDemo
	});
	addMemberToPlace(placeId, uid);
};

export const listenForUser = (uid: string, callback: (user: UserData) => void) => {
	try {
		const basePath = `users/${uid}`;
		const ref = doc(db, basePath);
		const unsubscribe: Unsubscribe = onSnapshot(ref, (querySnapshot) => {
			if (querySnapshot.exists()) {
				const data = querySnapshot.data();
				const user: UserData = {
					uid,
					displayName: data.displayName,
					email: data.email,
					placeId: data.placeId,
					isDemo: data.isDemo
				};
				callback(user);
			}
		});
		return unsubscribe;
	} catch (error) {
		console.error('api/users: Error listening for user', error);
	}
};
