import { debugLog } from '$lib/utils';
import { addDoc, arrayUnion, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import type { Place } from '../types';
import { db } from '../firebase';

/**
 *
 * @param placeId The id of the place to retrieve
 * @returns Place if found, null otherwise
 */
export const getPlace = async (placeId: string): Promise<Place | null> => {
	if (!placeId) {
		return null;
	}
	const path = `places/${placeId}`;
	const ref = doc(db, path);
	const snapshot = await getDoc(ref);
	if (snapshot.exists()) {
		const data = await snapshot.data();
		return { id: snapshot.id, members: data.members, secret: data.secret } as Place;
	} else {
		return null;
	}
};

/**
 *
 * @param members uids of the place members
 * @returns id of the newly created place
 */
export const addPlace = async (members: string[], isDemo: boolean): Promise<string> => {
	debugLog('Adding place for members', members);
	const path = `places/`;
	const ref = collection(db, path);
	// const secret =
	// 	Math.random().toString(36).substring(2, 20) + Math.random().toString(36).substring(2, 24);
	const place: Place = { members, isDemo };
	const newPlaceRef = await addDoc(ref, place);
	return newPlaceRef.id;
	// const newPlace: Place = (await getDoc(newPlaceRef)).data() as Place;
	// return newPlace;
};

export const updatePlace = async (place: Place): Promise<void> => {
	debugLog('Updating place', place);
	const path = `places/${place.id}`;
	const ref = doc(db, path);
	delete place.id;
	return setDoc(ref, place);
};

export const addMemberToPlace = async (placeId: string, uid: string): Promise<void> => {
	const path = `places/${placeId}`;
	const ref = doc(db, path);
	return updateDoc(ref, { members: arrayUnion(uid) });
};
